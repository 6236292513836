<template>
  <div
    class="w-full h-full"
  >
    <popup-chat :want-to-make-offer="activeChat" @oga="retourChat"/>
    <div
      class="w-full h-full all p-5 pl-6 pr-6 cursor-pointer"
      data-aos="fade-right"
      data-aos-duration="1000">

      <div class="flex web">
        <img
          src="../../../assets/icons/vehicule.svg"
          alt="" class="h-12 mr-4 mt-2"
          v-if="item.product.categorie === 'AUTO' || item.product.categorie === 'Automobile'" @click="selected">
        <img src="../../../assets/icons/moto.svg" alt="" class="h-12 mr-4 mt-2" v-if="item.product.categorie === 'MOTO' || item.product.categorie === 'Moto'" @click="selected">
        <img src="../../../assets/icons/voyage.svg" alt="" class="h-12 mr-4 mt-2" v-if="item.product.categorie === 'VOYAGE' || item.product.categorie === 'Voyage'" @click="selected">
        <img src="../../../assets/icons/mrh.svg" alt="" class="h-12 mr-4 mt-2" v-if="item.product.categorie === 'MRH' || item.product.categorie === 'Habitation'" @click="selected">

        <div class="md:w-2/5 mr-5" @click="selected">
          <div class="nom">
            <span v-if="item.product.categorie === 'AUTO' || item.product.categorie === 'Automobile'">{{details.carBrand}} - {{details.carRegistration}}</span>
            <span v-if="item.product.categorie === 'MOTO' || item.product.categorie === 'Moto'">{{details.motorBrand}} - {{details.motoRegistration}}</span>
            <span v-if="item.product.categorie === 'MRH' || item.product.categorie === 'Habitation'">{{details.habitationType.toUpperCase()}}</span>
            <span v-if="item.product.categorie === 'VOYAGE' || item.product.categorie === 'Voyage'">{{details.ownerCountry.name}} - {{details.country.name}}</span>
          </div>

          <div class="expire mt-2" @click="selected">Expire dans {{expireDate(item.expireAt)}} jours</div>

          <div class="active-bout button flex items-center mt-2" v-if="expireDate(item.expireAt) > 0 && !item.isSuspend" @click="selected">
            <div>Active</div>
          </div>

          <div class="expire-bout button flex items-center" v-if="expireDate(item.expireAt) > 0 && item.isSuspend" @click="selected">
            <div>Inactive</div>
          </div>

          <div class="expire-bout button flex items-center" v-if="expireDate(item.expireAt) <= 0" @click="selected">
            <div>Expirée</div>
          </div>
        </div>

        <div class="w-3/5 flex">
          <div class="nom w-1/3" @click="selected">
            {{details.financeData.primeTTC.toLocaleString()}} Fr
          </div>

          <div class="nom w-1/3 flex" @click="selected">
            <svg-icon
              name="copy"
              class="h-10 mr-4"
              original
            />
            <div>
              <div class="enonce">
                Police
              </div>
              <div class="resultat">
                Documents
              </div>
            </div>
          </div>

          <div class="nom w-1/3 flex"  @click="assistance">
            <svg-icon
              name="help"
              class="h-10 mr-4"
              original
            />
            <div class="is-clic">
              <div class="enonce">
                Besoin d’aide ?
              </div>
              <div class="resultat">
                Chat, Assistance
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile">
          <div class="flex justify-end w-full">
            <div class="active-bout button flex items-center mt-2" v-if="expireDate(item.expireAt) > 0" @click="selected">
              <div>Active</div>
            </div>

            <div class="expire-bout button flex items-center" v-if="expireDate(item.expireAt) <= 0" @click="selected">
              <div>Expirée</div>
            </div>
          </div>

        <div class="flex w-full">
          <img
            src="../../../assets/icons/vehicule.svg"
            alt="" class="h-12 mr-4 mt-2"
            v-if="item.product.categorie === 'AUTO' || item.product.categorie === 'Automobile'" @click="selected">
          <img src="../../../assets/icons/moto.svg" alt="" class="h-10 mr-4 mt-2" v-if="item.product.categorie === 'MOTO' || item.product.categorie === 'Moto'" @click="selected">
          <img src="../../../assets/icons/voyage.svg" alt="" class="h-10 mr-4 mt-2" v-if="item.product.categorie === 'VOYAGE' || item.product.categorie === 'Voyage'" @click="selected">
          <img src="../../../assets/icons/mrh.svg" alt="" class="h-10 mr-4 mt-2" v-if="item.product.categorie === 'MRH' || item.product.categorie === 'Habitation'" @click="selected">

          <div class="md:w-2/5 mr-2" @click="selected">
            <div class="nom">
              <span v-if="item.product.categorie === 'AUTO' || item.product.categorie === 'Automobile'">{{details.carBrand}} - {{details.carRegistration}}</span>
              <span v-if="item.product.categorie === 'MOTO' || item.product.categorie === 'Moto'">{{details.motorBrand}} - {{details.motoRegistration}}</span>
              <span v-if="item.product.categorie === 'MRH' || item.product.categorie === 'Habitation'">{{details.habitationType.toUpperCase()}}</span>
              <span v-if="item.product.categorie === 'VOYAGE' || item.product.categorie === 'Voyage'">{{details.ownerCountry.name}} - {{details.country.name}}</span>
            </div>

            <div class="expire mt-2" @click="selected">Expire dans {{expireDate(item.expireAt)}} jours</div>
          </div>
        </div>

        <div class="w-full flex justify-end">
          <svg-icon
            name="help"
            class="h-5"
            original
            @click="assistance"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import popupChat from '@/components/popup/popupChat'

export default {
  name: 'index',
  components: {
    popupChat
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      details: null,
      activeChat: false
    }
  },
  created () {
    this.details = JSON.parse(this.item.meta)
  },

  methods: {
    selected () {
      this.$emit('oga', this.item)
    },
    assistance () {
      this.activeChat = true
    },

    retourChat (answer) {
      this.activeChat = answer
    },

    expireDate (date) {
      const now = new Date().getTime()
      const temps = parseInt(new Date(date).getTime()) - now
      const jours = temps / (1000 * 3600 * 24)
      return jours.toFixed(0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .nom{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    color: #000000;
  }
div{
  font-family: 'Mulish';
}
.mobile{
  display: none;
}
.expire{
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #909090;
}
.all{
  background: #FFFFFF;
  border-radius: 20px;
}
.all:hover{
  box-shadow: 2px -1px 9px 0px #00000033;
}
.sous{
  font-weight: bold;
  font-size: 18px;
  line-height: 160%;
  color: #000000;
}

.resultat{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #909090;
  font-family: $popins;
}

.enonce{
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  font-family: $popins;
}

.button{
  height: 36.64px;
  background: #00BF53;
  border-radius: 18.3205px;
  font-weight: 500;
  font-size: 19.7298px;
  line-height: 26px;
  font-family: $font-default;
  color: #FFFFFF;
  border: none;
  width: 50%;
}
.expire-bout{
  background-color: #FF787F;
}
.svg-fill{
  fill: #FBD4D6;
}
.enonce:hover, .resultat:hover{
  color: $base-color;
}

@media only screen and (max-width: 700px) {
  .mobile{
    display: block;
  }
  .web{
    display: none;
  }
  .button{
    height: 20.64px;
    font-size: 10px;
    width: 20%;
  }
  .all{
    padding: 15px 25px 25px 25px !important;
  }
  .nom{
    font-size: 16px;
  }
  .expire{
    font-size: 13px;
    margin-top: 0px !important;
  }
  img{
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
.all{
  padding: 20px !important;
}
  .nom{
    font-size: 20px;
  }
  div{
    font-family: 'Mulish';
  }
  .expire{
    font-size: 15px;
  }
  .sous{
    font-size: 14px;
  }

  .resultat{
    font-size: 10px;
    display: none;
  }

  .enonce{
    font-size: 12px;
    display: none;
  }

  .button{
    height: 30.64px;
    font-size: 13.7298px;
    width: 50%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){

}
</style>
