import { render, staticRenderFns } from "./textareaZone.vue?vue&type=template&id=655105a4&scoped=true&"
import script from "./textareaZone.vue?vue&type=script&lang=js&"
export * from "./textareaZone.vue?vue&type=script&lang=js&"
import style0 from "./textareaZone.vue?vue&type=style&index=0&id=655105a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "655105a4",
  null
  
)

export default component.exports