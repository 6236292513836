<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container md:flex h-full"
        >
          <div class="w-full bloc1 p-4">
            <div class="flex items-center mb-6 md:pl-6">
              <div class="titre1 mt-4 w-2/3">Choisissez la couverture</div>
              <div class="w-1/3 flex justify-end">
                <div class="w-10 h-10 rounded-full mr-4 close md:mt-0 mt-3 flex items-center cursor-pointer justify-center" @click="fermer">
                  <div>X</div>
                </div>
              </div>
            </div>

            <div class="md:pl-6 md:pr-6 mb-6">
              <div class="flex items-center justify-center">
                <div
                  class="bloc w-1/2 mr-5 p-6"
                  @click="goto('automobile')"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <div class="flex w-full justify-center">
                    <img src="../../assets/icons/vehicule.svg" alt="" class="">
                  </div>
                  <div class="text-center titre">Automobile</div>
                </div>

                <div
                  class="bloc w-1/2 p-6"
                  @click="goto('moto')"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <div class="flex w-full justify-center">
                    <img src="../../assets/icons/moto.svg" alt="" class="">
                  </div>
                  <div class="text-center titre">Moto</div>
                </div>
              </div>

              <div class="flex items-center justify-center mt-5">
                <div
                  class="bloc w-1/2 mr-5 p-6"
                  @click="goto('mrh')"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div class="flex w-full justify-center">
                    <img src="../../assets/icons/mrh.svg" alt="" class="">
                  </div>
                  <div class="text-center titre">Habitation</div>
                </div>

                <div
                  class="bloc w-1/2 p-6"
                  @click="goto('voyage')"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div class="flex w-full justify-center">
                    <img src="../../assets/icons/voyage.svg" alt="" class="">
                  </div>
                  <div class="text-center titre">Voyage</div>
                </div>
              </div>

              <div class="flex items-center justify-center mt-5">
                <div
                  class="bloc w-1/2 mr-5 p-6"
                  @click="sante()"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div class="flex w-full justify-center">
                    <img src="../../assets/icons/sante.svg" alt="" class="">
                  </div>
                  <div class="text-center titre mt-2">Individuel Santé</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },
    goto (index) {
      this.$store.dispatch('position', 1)
      this.$store.dispatch('saveFirstDevis', null)
      this.$router.push({ path: '/accueil', query: { product: index } })

      this.$emit('oga', false)
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    sante () {
      window.open('https://tarification.aps-benin.com/?code=100', '_blank')
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 60%;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre{
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #191919;
    }

    .titre1{
      font-weight: bold;
      font-size: 28px;
      line-height: 180%;
      color: #000000;
    }

    .bloc{
      background: #FFFFFF;
      border: 1px solid #A9B2C6;
      box-sizing: border-box;
      border-radius: 20px;
    }
    .bloc:hover{
      border: 3px solid #006FB1;
    }

    .close{
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      color: #FF4B55;
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }

      .bloc{
        padding: 40px 20px 40px 20px !important;
      }
      img{
        height: 3rem;
      }

    }

    @media screen and (max-width: 700px){
      .success-container{
        width: 100%;
        border-radius: 0px;
        height: 100vh;
        padding: 10px !important;
      }
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 13px;
      }

      .titre1{
        font-weight: bold;
        font-size: 23px;
        line-height: 180%;
        color: #000000;
      }
      .bloc{
        padding: 30px 20px 30px 20px !important;
      }
      img{
        height: 2rem;
      }

      .button{
        width: 100%;
        height: 50px;
        font-size: 17px;
      }
    }

    @media screen and (max-width: 325px){
      .bloc{
        padding: 10px !important;
      }
    }
</style>
