<template>
<div class="w-full h-full all pb-6 pr-5">
  <div class="titre mt-5">
    Laissez-nous un avis
  </div>
  <div class="sous-titre mt-2">
    Comment trouvez-vous votre expérience sur AAB Mobile ?
  </div>

  <div class="flex justify-center mt-5">
    <div class="w-10 mr-5 ml-5 cursor-pointer" v-for="(item, index) in rate" :key="index" @click="selected(index)">
    <img src="../../../assets/icons/starOn.svg" alt="" class="h-10" v-if="index <= position">
    <img src="../../../assets/icons/starOff.svg" alt="" class="h-10" v-if="index > position">
    </div>
  </div>
  <textarea name="" v-model="message" class="mt-5 p-4"/>
  <div class="button w-full mt-5" @click="send">
    Envoyer
  </div>
</div>
</template>

<script>
import { db } from '@/main'

export default {
  name: 'index',
  props: {
    firebaseId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      rate: ['', '', '', '', ''],
      position: 0,
      message: '',
      user: null
    }
  },
  mounted () {
    this.controleUser()
  },
  methods: {
    send () {
      const collec = db.collection('notices').doc()
      collec.set({
        notice: this.message,
        noticeId: collec.id,
        createdAt: new Date().getTime(),
        rate: this.position,
        user: this.user
      })
        .then((response) => {
          this.$emit('oga', false)
          console.log('lastMessage successfully written!')
        })
        .catch((error) => {
          console.error('Error writing document: ', error)
        })
    },

    controleUser () {
      db.collection('users').where('id', '==', this.firebaseId)
        .get()
        .then((response) => {
          response.forEach((doc) => {
            this.user = doc.data()
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })
    },

    selected (index) {
      this.position = index
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
textarea{
  background: #F2F2F2;
  border-radius: 10px;
  height: 248px;
  width: 100%;
}
.titre{
  font-weight: bold;
  font-size: 16px;
  line-height: 153%;
  color: #02548B;
}
.bloc{
  background: #FFFFFF;
  border: 0.84375px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 8.4375px;
}
.button{
  background-color: $base-color;
  height: 60px;
  font-weight: 900;
  font-size: 18px;
  line-height: 153%;
  color: #FFFFFF;
}
.sous-titre{
  font-weight: normal;
  font-size: 14px;
  line-height: 153%;
  color: #979797;
}
@media only screen and (max-width: 700px) {
  .all{
    width: 100%;
    height: 140.83px;
  }
  .nom{
    font-size: 13.6275px;
    line-height: 25px;
  }
}
</style>
