<template>
<div class="w-full h-full all pb-6 pr-5">
  <div class="flex justify-center">
    <svg-icon
      name="thank-you"
      class="h-16 cursor-pointer mt-6 text-center"
      original
    />
  </div>

  <div class="text-center p-6">
    Merci pour votre retour. Nous les prenons en compte pour améliorer continuellement votre expérience sur notre application.
  </div>

  <div class="flex justify-center">
    <div class="button w-4/5 mt-2" @click="send">
      OK
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'index',
  props: {
    donnes: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      message: ''
    }
  },
  methods: {
    send () {
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
textarea{
  background: #F2F2F2;
  border-radius: 10px;
  height: 248px;
  width: 100%;
}
.titre{
  font-weight: bold;
  font-size: 16px;
  line-height: 153%;
  color: #02548B;
}
.bloc{
  background: #FFFFFF;
  border: 0.84375px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 8.4375px;
}
.button{
  background-color: $base-color;
  height: 60px;
  font-weight: 900;
  font-size: 18px;
  line-height: 153%;
  color: #FFFFFF;
}
@media only screen and (max-width: 700px) {
  .all{
    width: 100%;
    height: 140.83px;
  }
  .nom{
    font-size: 13.6275px;
    line-height: 25px;
  }
}
</style>
