<template>
  <div class="w-full h-full all pb-6 pr-5">
    <div class="titre mt-5">
      Rapporter un dysfonctionnement
    </div>
    <textarea name="" v-model="message" class="mt-6 p-4"/>
    <div class="button w-full mt-5" @click="send">
      Envoyer
    </div>
  </div>
</template>

<script>
import { db } from '@/main'

export default {
  name: 'index',
  props: {
    firebaseId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      message: ''
    }
  },

  mounted () {
    this.controleUser()
  },

  methods: {
    send () {
      const collec = db.collection('reports').doc()
      collec.set({
        report: this.message,
        reportId: collec.id,
        createdAt: new Date().getTime(),
        user: this.user
      })
        .then((response) => {
          this.$emit('oga', false)
          console.log('lastMessage successfully written!')
        })
        .catch((error) => {
          console.error('Error writing document: ', error)
        })
      this.$emit('oga', false)
    },

    controleUser () {
      db.collection('users').where('id', '==', this.firebaseId)
        .get()
        .then((response) => {
          response.forEach((doc) => {
            this.user = doc.data()
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
textarea{
  background: #F2F2F2;
  border-radius: 10px;
  height: 248px;
  width: 100%;
}
.titre{
  font-weight: bold;
  font-size: 16px;
  line-height: 153%;
  color: #02548B;
}
.bloc{
  background: #FFFFFF;
  border: 0.84375px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 8.4375px;
}
.button{
  background-color: $base-color;
  height: 60px;
  font-weight: 900;
  font-size: 18px;
  line-height: 153%;
  color: #FFFFFF;
}
@media only screen and (max-width: 700px) {
  .all{
    width: 100%;
    height: 140.83px;
  }
  .nom{
    font-size: 13.6275px;
    line-height: 25px;
  }
}
</style>
