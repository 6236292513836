<template>
  <section class="add-team absolute w-full h-full z-10" @click="clickOnBackground">
    <div
      class="popup-container rounded">
      <div class="popup rounded" @click.prevent.stop>
        <slot @click.prevent.stop></slot>
      </div>
    </div>
  </section>
</template>

<script>export default {
  name: 'popup-base',
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
    clickOnBackground () {
      this.$emit('clickOnBackground', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/mixin";
.add-team {
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  top: 0;
  left: 0;
  position: fixed
}
.popup-container {
  display: table-cell;
  vertical-align: middle;
  position: absolute;
  right: 0px;
  margin-top: 0px;
  width: 40%;
}
.popup {
  width: 100%;
  -webkit-animation: scale-up-center 0.7s cubic-bezier(0.400, 0.600, 0.800, 1) both;
  animation: scale-up-center 0.7s cubic-bezier(0.400, 0.600, 0.800, 1) both;
}

@keyframes bounce{
  from {transform: translateY(-2px);}
  to   {transform: translateY(-20px);}
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
 @media screen and (max-width: 700px){
   .popup-container {
     width: 100%;
   }
}
</style>
