<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal">
        <div
          class="success-container h-full"
          :class="{'all': position === 'retour' }"
        >
          <div class="flex items-center p-5" @click="fermer">
            <div class="titre">Assistance</div>
          </div>

          <div class="bande flex mt-5">
            <div class="w-1/5 text-center item p-2" :class="{'active': position === 'chat' }" @click="selectOption('chat')">
              Chat
            </div>
            <div class="w-1/4 text-center item p-2" :class="{'active': position === 'retour' }" @click="selectOption('retour')">
              Retour utilisateur
            </div>
          </div>

          <div class="pl-5 discute" v-if="position === 'chat'">
            <all-messages :donnes="messageData" :firebaseId="firebaseId" v-if="firebaseId"/>
          </div>

          <div class="pl-5 discute" v-if="position === 'retour'">
            <retour-client :firebaseId="firebaseId" v-if="firebaseId !== null"/>
          </div>
        </div>

        <div class="bas flex p-4 pl-6 pr-6 items-center">
          <svg-icon
            name="happy"
            class="h-6 mr-4"
            original
            @click="openEmoji"
          />
          <input type="text" class="h-12 w-full" v-model="message" placeholder="Ecrire votre message...">
          <svg-icon
            name="send"
            class="h-12"
            original
            @click="save"
          />
        </div>
        <emoji
          v-if="activeEmoj===true"
          @oga="retouremoji"
        />
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupBase2'
import { db } from '@/main'
import allMessages from '@/components/cedro/chat/allMessages'
import emoji from '@/components/cedro/chat/emoji'
import retourClient from '@/components/cedro/chat/retourClient'

export default {
  name: 'Success',
  components: { PopupBase, allMessages, emoji, retourClient },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      messageData: [],
      firebaseId: null,
      position: 'chat',
      message: '',
      activeEmoj: false,
      user: null,
      chatId: null
    }
  },

  computed: {
    // // eslint-disable-next-line vue/return-in-computed-property
    // trackMessage: function () {
    //   if (this.chatId !== null) {
    //     this.getMessages()
    //   }
    // }
  },

  mounted () {
    this.firebaseId = this.parseJwt(this.$store.getters.tokenState).firestoreId
    this.myChat()
    this.controleUser()
  },

  methods: {
    openEmoji () {
      this.activeEmoj = !this.activeEmoj
    },

    getMessages () {
      const tab = []
      db.collection('messages').doc(this.chatId).collection('messages')
        .get()
        .then((response) => {
          response.forEach((doc) => {
            tab.push(doc.data())
          })
          this.messageData = tab
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    },

    selectOption (index) {
      this.position = index
    },

    retouremoji (answer) {
      this.message = this.message + answer.native
      this.activeEmoj = false
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      // console.log(JSON.parse(jsonPayload))
      return JSON.parse(jsonPayload)
    },

    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    conversion (index) {
      return new Date(parseInt(index)).toLocaleString()
    },

    myChat () {
      db.collection('chats').where('createdBy', '==', this.firebaseId)
        .get()
        .then((response) => {
          response.forEach((doc) => {
            this.chatId = doc.data().chatId
          })

          this.getMessages()
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    },

    save () {
      const baba = {}
      const createdat = new Date().getTime()
      const uniqueID = new Date()
      const myRandom = Math.floor(Math.random() * 1000)
      const idd = uniqueID.getTime() + '' + myRandom
      baba.id = idd
      baba.createdAt = createdat
      baba.content = this.message
      baba.sendBy = this.firebaseId
      baba.isRead = false

      // this.messageData.push(baba)
      // this.message = ''
      if (this.messageData.length === 0) {
        this.saveFirst(baba)
      } else {
        this.saveOld(baba)
      }
    },
    // Old Message session
    saveOld (info) {
      db.collection('messages').doc(this.chatId).collection('messages').doc(info.id).set({
        id: info.id,
        createdAt: info.createdAt,
        content: this.message,
        isRead: false,
        sendBy: this.firebaseId
      })
        .then((response) => {
          this.messageData.push(info)
          this.message = ''
        })
        .catch((error) => {
          console.error('Error writing document: ', error)
        })
    },

    saveOldLastMessage (info) {
      db.collection('chats').doc(this.chatId).update({
        lastMessage: info
      })
        .then((response) => {
        })
        .catch((error) => {
          console.error('Error writing document: ', error)
        })
    },

    // New message session
    saveFirst (info) {
      const colec = db.collection('messages').doc()
      console.log('collection steep')
      console.log(colec)
      colec.collection('messages').doc(info.id).set({
        id: info.id,
        createdAt: info.createdAt,
        content: this.message,
        isRead: false,
        sendBy: this.firebaseId
      })
        .then((response) => {
          this.messageData.push(info)
          this.message = ''
          this.saveLastMessage(info, colec.id)
        })
        .catch((error) => {
          console.error('Error writing document: ', error)
        })
    },

    saveLastMessage (info, id) {
      db.collection('chats').doc(id).set({
        chatId: id,
        createdAt: new Date().getTime(),
        lastMessage: info,
        monitorId: '',
        creatorData: this.user,
        monitorData: {
          fullname: '',
          id: '',
          isAdmin: false,
          isSuperAdmin: false,
          mail: '',
          pictureUrl: ''
        },
        firstMessage: info,
        object: '',
        createdBy: this.firebaseId
      })
        .then((response) => {

        })
        .catch((error) => {
          console.error('Error writing document: ', error)
        })
    },

    controleUser () {
      db.collection('users').where('id', '==', this.firebaseId)
        .get()
        .then((response) => {
          response.forEach((doc) => {
            this.user = doc.data()
          })
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
        })
    }

  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;

    }
    .cardinal{
      width: 100%;
      height: 100vh;
    }
    .svg-icon{
      fill: white;
    }
    .discute{
      height: 80%;
    }
    .item{
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
      cursor: pointer;
    }
    .active{
      border-bottom: 2px solid $base-color;
      color: $base-color;
    }
    .bande{
      border-bottom: 1px solid #A9B2C6;
    }
    .success-container {
      width: 100%;
      background-color: #fff;
      padding: 0px;
      height: 90%;
      // border-radius: 15px;
      font-family: $font-default;
      // overflow-y: scroll;
    }
    .all{
      height: 100%;
    }
    .bas{
      background: #F2F2F2;
      // height: 85px;
      height: 10%;
      //border-radius: 0px 0px 15px 15px;
    }
    input{
      background: #F2F2F2;
      font-size: 18px;
    }
    .success-container::-webkit-scrollbar {
      display: none;
    }
    .titre {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 144%;
      letter-spacing: 0.04em;
      color: #000000;

    }
    .sous-titre{
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
      }
      .titre{
        font-size: 20px;
      }
      .button{
        width: 100%;
      }
    }
</style>
