<template>
<div class="w-full h-full all pb-6" v-chat-scroll>
  <div v-if="donnes.length === 0" class="mt-6 text-center">Vous n'avez aucun message</div>
  <div v-for="(item, index) in donnes" :key="index" class="mt-2">
    <div class="flex items-center mb-5" v-if="firebaseId !== null && item.sendBy !== firebaseId">
      <img src="../../../assets/images/png/aai.jpg" alt="" class="h-10 w-10 rounded-full mr-2">
      <div class="messageBox p-3 w-2/3">
        {{ item.content }}
      </div>
    </div>

    <div class="flex justify-end mb-3" v-if="firebaseId !== null && item.sendBy === firebaseId">
      <div class="messageRight p-3 w-2/3">
       {{ item.content }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'index',
  props: {
    donnes: {
      type: Array,
      default: null
    },
    firebaseId: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
div{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #4F4F4F;
}
.all{
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
}
.all::-webkit-scrollbar {
  display: none;
}
.messageBox{
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
.messageRight{
  background: #ECF0F7;
  border-radius: 3px;
}
@media only screen and (max-width: 700px) {
  .all{
    width: 100%;
    height: 140.83px;
  }
  .nom{
    font-size: 13.6275px;
    line-height: 25px;
  }
}
</style>
