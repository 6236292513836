<template>
<div class="w-full h-full all pb-6">
  <popupShare :want-to-make-offer="activeShare" :donne="donnes" @oga="retourShare"/>
  <div class="titre mt-5 pr-12" v-if="position === 1">
    Vos retours nous permettent de nous améliorer continuellement.
  </div>

  <div class="pl-5 pr-6" v-if="position === 1">
    <div class="bloc flex mt-4 items-center p-4" @click="selected(1)">
      <svg-icon
        name="bug"
        class="h-12 cursor-pointer mr-4"
        original
      />
      <div>Rapporter un dysfonctionnement</div>
    </div>

    <div class="bloc flex mt-4 items-center p-4" @click="selected(2)">
      <svg-icon
        name="message"
        class="h-12 cursor-pointer mr-4"
        original
      />
      <div>Laisser un avis général </div>
    </div>

    <div class="bloc flex mt-4 items-center p-4" @click="selected(3)">
      <svg-icon
        name="souscript"
        class="h-12 cursor-pointer mr-4"
        original
      />
      <div>Suggestion sur nos produits</div>
    </div>

    <div class="bloc flex mt-4 items-center p-4" @click="selected(4)">
      <svg-icon
        name="retour"
        class="h-12 cursor-pointer mr-4"
        original
      />
      <div>Partager avec des amis</div>
    </div>
  </div>

  <textarea-zone v-if="position === 2" @oga="retourArea" :firebaseId="firebaseId"/>
  <success v-if="position === 6" @oga="retourSuccess"/>
  <avis v-if="position === 4" :firebaseId="firebaseId" @oga="retourArea"/>
  <suggestion v-if="position === 3" @oga="retourArea" :firebaseId="firebaseId"/>
</div>
</template>

<script>
import textareaZone from './textareaZone'
import success from './success'
import avis from './avis'
import suggestion from './suggestion'
import popupShare from '@/components/popup/popupShare'

export default {
  name: 'index',
  components: { textareaZone, success, avis, suggestion, popupShare },
  props: {
    firebaseId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      position: 1,
      selectedItem: null,
      activeShare: false,
      donnes: {
        url: 'http://oremi-aab.surge.sh',
        label: 'OREMI',
        description: 'OREMI'
      }
    }
  },
  methods: {
    selected (index) {
      if (index === 1) {
        this.position = 2
      }
      if (index === 2) {
        this.position = 4
      }
      if (index === 3) {
        this.position = 3
      }
      if (index === 4) {
        this.activeShare = true
      }
    },

    retourShare (answer) {
      this.activeShare = false
    },

    retourArea (answer) {
      this.position = 6
    },

    retourSuccess (answer) {
      this.position = 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
div{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: black;
}
.bloc{
  background: #FFFFFF;
  border: 0.84375px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 8.4375px;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .all{
    width: 100%;
    height: 140.83px;
  }
  .nom{
    font-size: 13.6275px;
    line-height: 25px;
  }
}
</style>
