<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo flex items-center"
      @clickOnBackground="logState"
    >
      <div class="success-container2">
        <div class="first">
          <div class="w-full flex items-center">
            <div class="w-2/3 p-6 titre">
              Partager avec vos amis
            </div>
            <div class="w-1/3 flex justify-end">
              <svg-icon
                name="close"
                class=" h-24 cursor-pointer icooo"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="entete w-full flex justify-center">
            <div class="alerte flex">
              <ShareNetwork
                class="mr-4"
                network="facebook"
                :url="donne.url"
                :title="donne.label"
                :description="donne.description"
                :quote="donne.description"
                :hashtags="donne.label"
              >
                <svg-icon
                  name="facebookShare"
                  class="h-12 cursor-pointer mr-4"
                  original
                />
              </ShareNetwork>

              <ShareNetwork
                class="mr-4"
                network="whatsapp"
                :url="donne.url"
                :title="donne.label"
                :description="donne.description"
                :quote="donne.description"
                :hashtags="donne.label"
              >
                <svg-icon
                  name="whatsappShare"
                  class="h-12 cursor-pointer mr-4"
                  original
                />
              </ShareNetwork>

              <ShareNetwork
                class="mr-4"
                network="linkedin"
                :url="donne.url"
                :title="donne.label"
                :description="donne.description"
                :quote="donne.description"
                :hashtags="donne.label"
              >
                <img
                  src="../../assets/icons/linkedinShare.svg"
                  alt=""
                  class="ico cursor-pointer mr-4"
                >
              </ShareNetwork>

              <ShareNetwork
                class="mr-4"
                network="messenger"
                :url="donne.url"
                :title="donne.label"
                :description="donne.description"
                :quote="donne.description"
                :hashtags="donne.label"
              >
                <img
                  src="../../assets/icons/messengerShare.svg"
                  alt=""
                  class="ico cursor-pointer mr-4"
                >
              </ShareNetwork>

              <ShareNetwork
                network="twitter"
                :url="donne.url"
                :title="donne.label"
                :description="donne.description"
                :quote="donne.description"
                :hashtags="donne.label"
              >
                <img
                  src="../../assets/icons/twiterShare.svg"
                  alt=""
                  class="ico cursor-pointer mr-4"
                >
              </ShareNetwork>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      status: true,
      url: ''
    }
  },
  created () {
  },
  methods: {
    fermer () {
      this.$emit('oga', false)
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
@import "../../assets/style/sass/mixin";
.success-container2 {
  margin-left: auto;
  margin-right: auto;
  width: 45%;
  background-color: white;
  border-radius: 15px;
  z-index: 1;
}
.first{
  width: 100%;
  background-color: white;
  border-radius: 15px;
}
.entete{
  padding: 50px;
}
.ico{
  height: 3rem;
}
.titre{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
}

@media screen and (max-width: 1024px) and (min-width: 601px){
  .success-container2 {
    width: 100%;
  }
  .titre{
    font-size: 25px;
  }
  .first{
    margin-right: 40px;
  }
}
@media screen and (max-width: 600px){
  .success-container2 {
    width: 100%;
  }
  .titre{
    font-size: 20px;
  }
  .all{
    padding: 10px 10px 20px 10px;
  }
  .success{
    width: 100%;
  }
  .mr-4{
    margin-right: 10px;
  }
  .entete{
    padding: 50px 10px 50px 10px;
  }
}
</style>
