import { render, staticRenderFns } from "./popupProduct.vue?vue&type=template&id=4186fdc6&scoped=true&"
import script from "./popupProduct.vue?vue&type=script&lang=js&"
export * from "./popupProduct.vue?vue&type=script&lang=js&"
import style0 from "./popupProduct.vue?vue&type=style&index=0&id=4186fdc6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4186fdc6",
  null
  
)

export default component.exports